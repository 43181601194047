import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, Authors, Pagination, Scroll } from "../../components"

const AuthorsPage = ({ data, pageContext }) => {
  const {
    allContentfulAuthor: { nodes: authors },
  } = data

  // console.log("data", data)
  // console.log("pagecontext", pageContext)

  return (
    <Wrapper>
      <Layout>
        <Authors title="Összes szerző (fordítások)" authors={authors} page />
        <Pagination pageContext={pageContext} />
        <Scroll
          // showBelow={1500}
          css="
        position: fixed; 
        right: 5rem; 
        bottom: 6rem; 
        font-size: 1.7rem;
        "
        />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  ${"" /* background from Poems.js  */}
  background: var(--clr-dark-5);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query AutorsQuery($skip: Int, $limit: Int) {
    allContentfulAuthor(
      filter: { authorFullName: { ne: "Pék Zoltán" } }
      limit: $limit
      skip: $skip
      sort: { fields: authorLastName, order: ASC }
    ) {
      nodes {
        authorFirstName
        authorMiddleName
        authorLastName
        authorFullName
        contentful_id
        createdAt(formatString: "YYYY-MM-DD")
        book {
          bookTitle
          contentful_id
          bookImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
    }
  }
`

export default AuthorsPage
